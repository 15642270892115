<template>
  <div>
    <PageHeader>
      <template slot="right">
      </template>
    </PageHeader>
    <div class="card">
      <div class="card-body">
        <h6>
          {{ `${$t('fields.result')} (${dataList.length})`}}
        </h6>
        <b-row align-v="center" class="mb-3">
          <b-col cols="12" md="3">
            <LimitSelect v-model="selectedLimit" />
          </b-col>
          <b-col cols="12" md="9">
            <b-row>
              <b-col cols="5">
                <b-input-group>
                  <b-form-input
                    v-model="search"
                    :placeholder="`${$t('fields.search')}...(ชื่อผู้ใช้/ รหัสสมาชิก/ ชื่อ) `"
                    class="mx-1"
                    type="search"
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-col>
                <MasterSelectInput 
                  :value="selectedMaster"
                  hide-label
                  @update="onMasterIdChange"
                />
              </b-col>
              <b-col>
                <AgentSelectInput
                  :master-id="selectedMaster"
                  :value="selectedAgent"
                  hide-label
                  @update="onAgentIdChange"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Table -->
        <b-table
          :busy="isLoading"
          :fields="fields"
          :items="dataList"
          responsive
          show-empty
        >
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(userAgent)="data">
            {{ data.value.agent.name }}
          </template>
          <template #cell(id)="data">
            {{ data.item.userAgent.username }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.value | datetime }}
          </template>

          <template #cell(browserUserAgent)="data">
            <b-button
              :id="`browserUserAgent-${data.item.id}`"
              size="sm"
              variant="light"
            >
              <i class="uil uil-desktop"></i>
            </b-button>
            <b-popover
              :target="`browserUserAgent-${data.item.id}`"
              placement="left"
              triggers="hover"
              variant="light"
            >
              {{ data.value}}
            </b-popover>
          </template>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </template>
          <template #empty="">
            <p class="text-center text-muted">{{ $t('messages.nothing_here')}}</p>
          </template>
        </b-table>
        <!-- pagination -->
      <PaginationInput :per-page="limit" :total="total" @update="(val) => currentPage = val"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  page: {
    title: 'ประวัติการเข้าใช้งาน',
  },
  components: {
    MasterSelectInput: () =>
    import('@components/master/master-select-input'),
    AgentSelectInput: () =>
    import('@components/agents/agents-select-input.vue'),
  },
  data() {
    return {
      selectedMaster: '',
      selectedAgent: '',
      search: '',
      selectedStatus: '',
      statusOptions: [
        { name: 'สำเร็จ', value: 'success' },
        { name: '-', value: 'active' },
        { name: '-', value: '-' },
      ],
      currentPage: 1,
      selectedLimit: 20,
      fields: [
        '#',
        {
          key: 'createdAt',
          label: 'เวลา',
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'userAgent',
          label: this.$t('agent.name'),
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'id',
          label: 'ยูสเซอร์เนม',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'ipAddress',
          label: 'IP',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'browserUserAgent',
          label: 'เบราว์เซอร์',
          thStyle: {
            minWidth: '100px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.user.isFetchingUserAccess,
    }),
    ...mapGetters(['userAccess']),
    dataList() {
      return this.userAccess.items || []
    },
    pagination() {
      return this.userAccess.meta
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: {
    currentPage(val) {
      if(val){
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    selectedLimit(val) {
      if(val) {
        this.currentPage > 1 ? this.currentPage = 1 : this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchUserAccess']),
    fetchData() {
      this.fetchUserAccess({
        limit: this.selectedLimit, 
        page: this.currentPage,
        search: this.search, 
        masterId: this.selectedMaster,
        agentId: this.selectedAgent, 
      })
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onMasterIdChange(masterId) {
      this.selectedMaster = masterId
      this.fetchData()
    },
    onAgentIdChange(agentId) {
      this.selectedAgent = agentId
      this.fetchData()
    },
  },
}
</script>
